import React, { ReactNode } from 'react'
import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

interface CustomModalProps extends Partial<ModalProps> {
  trigger: ReactNode
  title?: string
  children: ReactNode
  hideCloseButton?: boolean
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  onTriggerClick?: () => void
  bodyProps?: ModalBodyProps
}

const CustomModal: React.FC<CustomModalProps> = ({
  hideCloseButton,
  trigger,
  title,
  children,
  onClose,
  onOpen,
  isOpen,
  onTriggerClick,
  bodyProps,
  ...props
}) => {
  const Trigger = React.cloneElement(trigger as React.ReactElement, {
    onClick: (e: any) => {
      e.stopPropagation()
      onOpen()
      onTriggerClick?.()
    }
  })

  return (
    <>
      {Trigger}

      <Modal
        motionPreset="scale"
        scrollBehavior="inside"
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        {...props}>
        <ModalOverlay />
        <ModalContent>
          {!!title && (
            <ModalHeader fontSize="sm" textAlign={'center'}>
              {title}
            </ModalHeader>
          )}
          {!hideCloseButton && (
            <ModalCloseButton aria-label="close" _focus={{ outline: 'none' }} zIndex={1} />
          )}
          <ModalBody
            mx="auto"
            maxW="container.xl"
            w="full"
            display="flex"
            alignItems="center"
            {...bodyProps}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CustomModal
