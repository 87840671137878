import { ImageVariants, MediaDTO } from '@/core/media/typings'
import { variantUrls } from '@/core/media/utils/variant-urls'

type UrlVariants = Record<ImageVariants, string> & { url: string }

export const mainMediasFactory = (medias: MediaDTO[]): UrlVariants[] => {
  const main = medias.filter((m) => m.isMain)
  const defaultImg = '/default-property.png'

  if (main.length) {
    return main.map(({ url }) => ({ url, ...variantUrls(url) }))
  }

  if (medias.length) {
    const url = medias[0].url
    return [{ url, ...variantUrls(url) }]
  }

  return [
    {
      url: defaultImg,
      thumb: defaultImg,
      [ImageVariants['1920x1080']]: defaultImg,
      [ImageVariants['600x600']]: defaultImg
    }
  ]
}
