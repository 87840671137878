import React, { useState } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import Image from 'next/image'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface ImageCarouselProps extends BoxProps {
  imgSrcs: string[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ imgSrcs, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        '.carousel-root': { width: '100%', height: 'full' },
        '.slider': { height: 'full' },
        '.slider-wrapper': { height: 'full' },
        '.carousel-slider': { height: 'full' },
        '.carousel .control-dots .dot': {
          display: imgSrcs.length > 1 ? 'inline-block' : 'none',
          width: 2,
          height: 2,
          borderRadius: '50%',
          backgroundColor: 'primary.200',
          boxShadow: 'none'
        },
        '.carousel .control-dots .dot.selected': {
          backgroundColor: 'primary'
        }
      }}>
      <Carousel infiniteLoop showStatus={false} showThumbs={imgSrcs.length > 1}>
        {imgSrcs.map((imgSrc, index) => (
          <Box
            key={index}
            h="100%"
            w="100%"
            position="relative"
            transition="transform .2s ease-in-out"
            _hover={{
              transform: 'scale(1.1)'
            }}>
            <ImageWithFallback
              key={index}
              src={imgSrc}
              alt={`Slide ${index}`}
              fallbackSrc="/default-cbre.jpg"
              priority={index === 0}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

export default ImageCarousel

interface ImageWithFallbackProps {
  src: string
  alt: string
  fallbackSrc: string
  priority?: boolean
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  fallbackSrc,
  priority
}) => {
  const [imgSrc, setImgSrc] = useState(src)

  return (
    <Box position="relative" w="full" h="full">
      <Image
        src={imgSrc}
        alt={alt}
        layout="fill"
        objectFit="cover"
        priority={priority}
        onError={() => setImgSrc(fallbackSrc)}
      />
    </Box>
  )
}
