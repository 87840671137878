import React, { FC, useCallback } from 'react'
import { Avatar, Heading, HStack, Icon, StackProps, Text, useToast, VStack } from '@chakra-ui/react'
import { FaMobile } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { BrokerDTO } from '@/core/broker/typings'
import {
  gtmEvent,
  RealestateDetailBrokerMailClick,
  RealestateDetailBrokerTelephoneClick
} from '@/utils/gtm'
import { variantUrls } from '@/core/media/utils/variant-urls'

interface BrokerContactCardProps extends StackProps {
  broker: BrokerDTO
}

const BrokerContactCard: FC<BrokerContactCardProps> = ({ broker, ...props }) => {
  const toast = useToast()

  const handleCopyText = useCallback(
    async (prop: 'phone' | 'email') => {
      try {
        switch (prop) {
          case 'phone': {
            gtmEvent<RealestateDetailBrokerTelephoneClick>(
              'realestate_detail_broker_telephone_click',
              {
                broker_id: broker.id
              }
            )
            await navigator.clipboard.writeText(broker.phone)
            break
          }
          case 'email': {
            gtmEvent<RealestateDetailBrokerMailClick>('realestate_detail_broker_mail_click', {
              broker_id: broker.id
            })
            await navigator.clipboard.writeText(broker.email)
            break
          }

          default:
            break
        }

        toast({
          title: 'Copiado',
          status: 'success',
          duration: 1000,
          isClosable: true
        })
      } catch (err) {
        toast({
          title: 'Algo salió mal.',
          status: 'error',
          duration: 1000,
          isClosable: true
        })
      }
    },
    [broker.email, broker.id, broker.phone, toast]
  )

  return (
    <HStack
      align="center"
      spacing={6}
      w="full"
      p={4}
      py={8}
      rounded="md"
      maxW="sm"
      bg="background.surface"
      {...props}>
      <VStack spacing={6} align="flex-start">
        <Heading as="h5" fontSize="3xl">
          Contacto
        </Heading>
        <Heading as="h6" fontSize="lg">
          {broker.name}
        </Heading>

        <HStack spacing={4}>
          <Icon boxSize={6}>
            <FaMobile />
          </Icon>
          <Text flex={1} fontSize="md" cursor="pointer" onClick={() => handleCopyText('phone')}>
            {broker.phone}
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Icon boxSize={6}>
            <MdEmail />
          </Icon>
          <Text flex={1} fontSize="md" cursor="pointer" onClick={() => handleCopyText('email')}>
            {broker.email}
          </Text>
        </HStack>
      </VStack>
      <Avatar src={variantUrls(broker.img)['600x600']} h={'80px'} w={'80px'} />
    </HStack>
  )
}

export default BrokerContactCard
