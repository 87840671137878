import React, { FC } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import './styles.css'

interface LoadingToastProps {}

const LoadingToast: FC<LoadingToastProps> = ({}) => {
  return (
    <Flex
      bg="background.default"
      shadow="lg"
      borderColor="primary"
      borderWidth={1}
      p={1}
      rounded="md"
      alignItems="center"
      justifyContent="center"
      w="50px"
      h="50px"
      ml="auto">
      <Spinner h="28px" w="28px" size="lg" />
    </Flex>
  )
}

export default LoadingToast
