import React, { FC } from 'react'
import { HStack, Skeleton, SkeletonCircle, SkeletonText, VStack, Wrap } from '@chakra-ui/react'
import { SEARCH_ITEM_SIZE } from '@/constants'

interface RealEstateItemSkeletonProps {
  isMobile?: boolean
}

const RealEstateItemSkeleton: FC<RealEstateItemSkeletonProps> = ({ isMobile }) => {
  return isMobile ? (
    <VStack w="full" gap={4} rounded="sm" p={4} align="stretch">
      <Skeleton w="full" h={SEARCH_ITEM_SIZE} />

      <SkeletonText mt={2} w="full" noOfLines={3} spacing="4" skeletonHeight="4" />

      <Wrap w="full" spacing={4}>
        <HStack h="40px" w="full" rounded="full" px={4}>
          <SkeletonCircle h={6} w={6} />
          <Skeleton h="50%" w="full" />
        </HStack>
        <HStack h="40px" w="full" rounded="full" px={4}>
          <SkeletonCircle h={6} w={6} />
          <Skeleton h="50%" w="full" />
        </HStack>
      </Wrap>
    </VStack>
  ) : (
    <HStack w="full" gap={0} rounded="sm">
      <Skeleton w={SEARCH_ITEM_SIZE} h={SEARCH_ITEM_SIZE} />
      <VStack p={2} flex={1} w="full" alignSelf="stretch" align="flex-start" justify="flex-start">
        <SkeletonText mt={2} w="full" noOfLines={6} spacing="4" skeletonHeight="4" />
        <Wrap w="full">
          <HStack h="40px" w="150px" rounded="full" px={1}>
            <SkeletonCircle h={6} w={6} />
            <Skeleton h="50%" w="full" flex={1} />
          </HStack>
          <HStack h="40px" w="150px" rounded="full" px={1}>
            <SkeletonCircle h={6} w={6} />
            <Skeleton h="50%" w="full" flex={1} />
          </HStack>
        </Wrap>
      </VStack>
    </HStack>
  )
}

export default RealEstateItemSkeleton
