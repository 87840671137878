import React, { FC } from 'react'
import { StackDivider, StackProps, Text, VStack } from '@chakra-ui/react'
import { RealEstateLightDTO } from '@/core/real-estate/typings'
import RealEstateSize from '@/app/(public)/components/RealEstateSize'
import RealEstateAddress from '@/app/(public)/components/RealEstateAddress'
import BrokersButtons from '@/app/(public)/components/BrokersButtons'

interface RealEstateListItemProps extends StackProps {
  item: RealEstateLightDTO
  noBrokers?: boolean
}

const RealEstateListItem: FC<RealEstateListItemProps> = ({ item, noBrokers, ...props }) => {
  return (
    <VStack align="flex-start" spacing={4} justify="space-evenly" cursor="pointer" {...props}>
      <Text color="gray.700" fontWeight="medium">
        {item.variant} a la {item.contractType?.toLowerCase()}
      </Text>
      <VStack spacing={2} w="full" divider={<StackDivider />}>
        <RealEstateAddress item={item} />
        <RealEstateSize data={item.size} />
        {!noBrokers ? (
          <BrokersButtons realEstate={JSON.parse(JSON.stringify(item))} brokers={item.brokers} />
        ) : null}
      </VStack>
    </VStack>
  )
}

export default RealEstateListItem
