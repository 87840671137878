'use client'
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  StackDivider,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import React, { FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CONTENT_HEIGHT,
  MAP_DEBOUNCE,
  MEXICO_CITY_COORDINATES,
  SEARCHBAR_HEIGHT
} from '@/constants'
import {
  AllowedPrices,
  AllowedSizes,
  ContractType,
  FilterRealEstateParams,
  MapLocation,
  PageSizeOptions,
  RealEstateGroup,
  RealEstateLightDTO,
  RealEstateSortOptions
} from '@/core/real-estate/typings'
import { usePaginate } from '@/hooks/usePaginate'
import { MapCenter } from '@/core/typings'
import RealEstateList from '@/app/(public)/components/RealEstateList'
import { MapWithMarkers } from '@/app/components/common/CustomMap'
import { debounce } from 'lodash'
import { Action, ReducerActionType, useSetReset } from '@/hooks/useSetReset'
import { Dropdown } from '@/app/components/common/Dropdown'
import { propIfExists } from '@/utils/prop-if-exists'
import { MultiSelectDropdown } from '@/app/components/common/MultiSelectDropdown/MultiSelectDropdown'
import useCheckboxes from '@/hooks/useCheckboxes'
import { AggregatePaginateResult } from 'mongoose'
import { parseSortObject } from '@/utils/parseSortObject'
import { createInitialFilters } from '@/utils/create-initial-filters'
import { getEnumKeyByValue } from '@/utils/get-enum-key-by-value'
import AddressSearch from '@/app/components/common/AddressSearch'
import { SizePopoverFilterWrapper } from '@/app/components/common/SizePopoverFilterWrapper'
import { PricePopoverFilterWrapper } from '@/app/components/common/PricePopoverFilterWrapper'
import ParamsUpdater from '@/app/(public)/components/ParamsUpdater'
import { useSearchParams } from 'next/navigation'
import { searchParamsToObject } from '@/utils/search-params-to-obj'
import { useLoadingToast } from '@/hooks/useLoadingToast'
import { ScrollDragContainer } from '@/app/(public)/components/ScrollDragContainer'
import { getPlaceTypeRadius, PlaceType } from '@/app/components/common/autocomplete/typings'
import ToolboxMenuItem from '@/app/components/common/ToolboxMenuItem'
import { TfiMoreAlt } from 'react-icons/tfi'
import SearchFiltersDrawer, { FilterItem } from '@/app/components/drawers/SearchFiltersDrawer'
import { RadiusSelector } from '@/app/components/common/RadiusSelector'
import CheckboxSelector from '@/app/components/CheckboxSelector'
import { SizeFilterWrapper } from '@/app/components/common/SizeFilterWrapper'
import { PriceFilterWrapper } from '@/app/components/common/PriceFilterWrapper'
import {
  gtmEvent,
  MapInteraction,
  SearchResultsMetadata,
  UserSearch,
  UserSearchResetClick
} from '@/utils/gtm'
import { v4 as uuidv4 } from 'uuid'
import { FaThList } from 'react-icons/fa'
import { IoMdMap } from 'react-icons/io'

const TOAST_ID = 'map-search'

type RealEstateGroupKeys = keyof typeof RealEstateGroup

interface HomeSearchProps {
  deviceType: 'desktop' | 'mobile'
}

const HomepageSearch: FC<HomeSearchProps> = ({ deviceType }) => {
  const isMobile = deviceType === 'mobile'
  const { isOpen: showMap, onToggle: toggleMap } = useDisclosure({
    defaultIsOpen: !isMobile
  })
  const searchParams = useSearchParams()
  const initialFilters = createInitialFilters(searchParamsToObject(searchParams))

  const [mapInfo, dispatchMapInfo] = useSetReset<MapCenter & { radius: number | null }>({
    lng: MEXICO_CITY_COORDINATES[0],
    lat: MEXICO_CITY_COORDINATES[1],
    radius: isMobile ? 220000 : null
  })

  const [zoom, setZoom] = useState(4)
  const [sizeFilter, dispatchSizeFilter] = useSetReset<{
    mtsMin: AllowedSizes
    mtsMax: AllowedSizes
  }>({
    mtsMin: initialFilters?.size?.mtsMin
      ? (initialFilters.size.mtsMin.toString() as AllowedSizes)
      : AllowedSizes.NoValue,
    mtsMax: initialFilters?.size?.mtsMax
      ? (initialFilters.size.mtsMax.toString() as AllowedSizes)
      : AllowedSizes.NoValue
  })

  const [priceFilter, dispatchPriceFilter] = useSetReset<{
    min: AllowedPrices
    max: AllowedPrices
  }>({
    min: initialFilters?.price?.min
      ? (initialFilters.price.min.toString() as AllowedPrices)
      : AllowedPrices.NoValue,
    max: initialFilters?.price?.max
      ? (initialFilters.price.max.toString() as AllowedPrices)
      : AllowedPrices.NoValue
  })

  const debouncedDispatchMapInfo = useMemo(
    () =>
      debounce((payload: Action<MapCenter & { radius: number }>) => {
        dispatchMapInfo(payload)
      }, MAP_DEBOUNCE),
    [dispatchMapInfo]
  )

  const [contractTypeFilter, setContractTypeFilter] = useState<keyof typeof ContractType | null>(
    initialFilters?.contractType
      ? getEnumKeyByValue(ContractType, initialFilters.contractType) || null
      : null
  )

  const mappedGroups = useMemo(
    () =>
      initialFilters?.group
        ? initialFilters.group
            .map((g) => getEnumKeyByValue(RealEstateGroup, g) || '')
            .filter((g) => g.length)
        : [],
    [initialFilters?.group]
  )

  const {
    selected: groupFilter,
    handleStringCheckBoxes: setGroupFilter,
    resetSelected: resetSelectedVariants
  } = useCheckboxes(mappedGroups)

  const {
    fetch: fetchPaginated,
    data: paginatedData,
    currentPage,
    currentSize,
    currentSort,
    setCurrentPage,
    setSize,
    pagination,
    isLoading,
    setSort,
    reset: resetListData
  } = usePaginate<RealEstateLightDTO>({ infiniteScroll: false })

  const filters: FilterRealEstateParams | null = useMemo(() => {
    if (!mapInfo.radius) return null
    return {
      maxDistance: mapInfo.radius,
      coordinates: [mapInfo.lng, mapInfo.lat] as MapLocation,
      ...propIfExists(
        'contractType',
        contractTypeFilter ? ContractType[contractTypeFilter] : undefined
      ),
      ...propIfExists(
        'group',
        groupFilter?.length
          ? (groupFilter as RealEstateGroupKeys[]).map((i) => RealEstateGroup[i])
          : undefined
      ),
      size: {
        mtsMin: parseInt(sizeFilter.mtsMin),
        mtsMax: parseInt(sizeFilter.mtsMax)
      },
      price: {
        min: parseInt(priceFilter.min),
        max: parseInt(priceFilter.max)
      }
    }
  }, [contractTypeFilter, mapInfo, priceFilter, sizeFilter, groupFilter])

  const [markersData, setMarkersData] = useState<RealEstateLightDTO[]>([])

  const fetchData = useCallback(async () => {
    if (!filters) return
    const payload = {
      page: currentPage,
      limit: currentSize,
      sort: parseSortObject(currentSort),
      ...filters
    }

    const searchId = uuidv4()

    gtmEvent<UserSearch>('user_search', {
      max_distance: payload.maxDistance,
      lng: payload.coordinates[0],
      lat: payload.coordinates[1],
      sort: payload.sort,
      size_max: payload.size?.mtsMax,
      size_min: payload.size?.mtsMin,
      price_max: payload.price?.max,
      price_min: payload.price?.min,
      page: currentPage,
      limit: currentSize,
      contract_type: payload.contractType,
      group: payload.group,
      address_input: addressInputRef.current?.value || '',
      search_correlation_id: searchId
    })

    return fetchPaginated(async () => {
      const res = await fetch('/api/real-estate/filter', {
        method: 'POST',
        body: JSON.stringify(payload),
        next: { revalidate: 60 }
      })
      const result = (await res.json()) as {
        paginationResults: AggregatePaginateResult<RealEstateLightDTO>
        singularResults: RealEstateLightDTO[]
      }

      gtmEvent<SearchResultsMetadata>('search_results_metadata', {
        total_pages: result.paginationResults.totalPages,
        total_elements: result.paginationResults.totalDocs,
        search_correlation_id: searchId
      })

      setMarkersData(result.singularResults)
      return result.paginationResults
    })
  }, [filters, currentPage, currentSize, currentSort, fetchPaginated])

  useEffect(() => {
    // Current page triggers a fetch to retrieve the next items.
    void fetchData()
  }, [currentPage, fetchData])

  useLoadingToast(TOAST_ID, isLoading)

  const handleContractTypeOptionChange = useCallback((value: keyof typeof ContractType | null) => {
    setContractTypeFilter(value)
  }, [])

  const realEstateGroups = Object.keys(RealEstateGroup).map((key) => ({
    value: key as RealEstateGroupKeys,
    label: RealEstateGroup[key as keyof typeof RealEstateGroup]
  }))

  const handleOnReset = useCallback(() => {
    gtmEvent<UserSearchResetClick>('user_search_reset_click', {})

    setMarkersData([])
    resetListData()
    resetSelectedVariants()
    setContractTypeFilter(null)
    dispatchSizeFilter({
      type: ReducerActionType.Set,
      payload: { mtsMin: AllowedSizes.NoValue, mtsMax: AllowedSizes.NoValue }
    })
    dispatchPriceFilter({
      type: ReducerActionType.Set,
      payload: { min: AllowedPrices.NoValue, max: AllowedPrices.NoValue }
    })
    dispatchMapInfo({
      type: ReducerActionType.Set,
      payload: {
        lng: MEXICO_CITY_COORDINATES[0],
        lat: MEXICO_CITY_COORDINATES[1],
        radius: isMobile ? 220000 : null
      }
    })
    setZoom(4)

    if (addressInputRef.current) {
      addressInputRef.current.value = ''
    }
  }, [
    dispatchMapInfo,
    dispatchPriceFilter,
    dispatchSizeFilter,
    isMobile,
    resetListData,
    resetSelectedVariants
  ])

  const addressInputRef = useRef<HTMLInputElement | null>(null)

  const mobileFilters: FilterItem[] = useMemo(() => {
    return [
      {
        title: 'Tipo de transacción',
        content: (
          <RadiusSelector
            isDisabled={isLoading}
            fontSize="sm"
            enum={ContractType}
            value={contractTypeFilter === null ? '' : contractTypeFilter}
            onOptionSelection={handleContractTypeOptionChange}
          />
        )
      },
      {
        title: 'Tipo de propiedad',
        content: (
          <CheckboxSelector
            disableAll={isLoading}
            options={realEstateGroups}
            selected={groupFilter as RealEstateGroupKeys[]}
            handleStringCheckBoxes={setGroupFilter}
          />
        )
      },
      {
        title: 'Tamaño',
        content: (
          <SizeFilterWrapper
            disableAll={isLoading}
            sizeFilter={sizeFilter}
            dispatch={dispatchSizeFilter}
          />
        )
      },
      {
        title: 'Precio',
        content: (
          <PriceFilterWrapper
            disableAll={isLoading}
            priceFilter={priceFilter}
            dispatch={dispatchPriceFilter}
          />
        )
      }
    ]
  }, [
    contractTypeFilter,
    dispatchPriceFilter,
    dispatchSizeFilter,
    handleContractTypeOptionChange,
    isLoading,
    priceFilter,
    realEstateGroups,
    setGroupFilter,
    sizeFilter,
    groupFilter
  ])

  const handleOnZoomUpdate = useCallback((z: number) => {
    gtmEvent<MapInteraction>('map_interaction', { type: 'zoom', zoom_level: z })
    setZoom(z)
  }, [])

  const handleOnMapDrag = useCallback(() => {
    gtmEvent<MapInteraction>('map_interaction', { type: 'drag' })
  }, [])

  const [listHasReachedBottom, setListHasReachedBottom] = useState(true)

  const isButtonTogglerVisible = useMemo(() => {
    if (showMap) {
      return true
    }

    if (pagination.totalPages === 1) {
      return true
    }
    return listHasReachedBottom
  }, [listHasReachedBottom, pagination.totalPages, showMap])

  return (
    <VStack divider={<StackDivider />} spacing={0} w="full" position="relative">
      <Button
        display={isMobile ? 'flex' : 'none'}
        opacity={isButtonTogglerVisible ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
        color="text.invert"
        leftIcon={<Icon boxSize={6} as={showMap ? FaThList : IoMdMap} color="text.invert" />}
        bg="primary.400"
        zIndex="banner"
        bottom={`calc(2.5% + env(safe-area-inset-bottom))`}
        rounded="md"
        position="fixed"
        left="50%"
        transform="translateX(-50%)"
        onClick={toggleMap}>
        {showMap ? 'Lista' : 'Mapa'}
      </Button>
      <Suspense>
        <ParamsUpdater filters={filters} />
      </Suspense>
      <Container
        as={HStack}
        flexDirection="row"
        px={4}
        py={2}
        maxW="container.xl"
        align="center"
        p={4}
        w="full"
        h={{ base: 'auto', md: SEARCHBAR_HEIGHT }}>
        <AddressSearch
          inputRef={addressInputRef}
          isDisabled={isLoading}
          flex={1}
          minW={{ base: 200, md: 350 }}
          center={mapInfo}
          onUpdate={(payload) => {
            dispatchMapInfo({
              type: ReducerActionType.Set,
              payload: {
                lat: payload.lat,
                lng: payload.lng,
                radius: !showMap ? getPlaceTypeRadius(payload.type) : null
              }
            })
            if (payload.type) {
              const type: PlaceType = payload.type
              setZoom(type)
            }
          }}
        />
        <SearchFiltersDrawer
          display={isMobile ? 'flex' : 'none'}
          filters={mobileFilters}
          onReset={handleOnReset}
          totalItems={pagination.totalDocs}
        />

        <Flex display={isMobile ? 'none' : 'flex'} overflow="hidden" w="full" alignItems="stretch">
          <ScrollDragContainer mouseScroll={false} w="full">
            <HStack w="full">
              <Dropdown
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                placeholder="Tipo de transacción"
                enum={ContractType}
                value={contractTypeFilter === null ? '' : contractTypeFilter}
                onOptionSelection={handleContractTypeOptionChange}
              />
              <MultiSelectDropdown
                placeholder="Tipo de propiedad"
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                maxW="40vw"
                minW={180}
                options={realEstateGroups}
                selected={groupFilter as RealEstateGroupKeys[]}
                handleStringCheckBoxes={setGroupFilter}
              />
              <SizePopoverFilterWrapper
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                sizeFilter={sizeFilter}
                dispatch={dispatchSizeFilter}
              />
              <PricePopoverFilterWrapper
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                priceFilter={priceFilter}
                dispatch={dispatchPriceFilter}
              />

              <ToolboxMenuItem
                minW="auto"
                w={10}
                h={10}
                label={'Más'}
                icon={TfiMoreAlt}
                aria-label={'más'}
                bg="background.surface"
                p={2}
                color="primary"
                groups={[
                  {
                    title: 'Más acciones',
                    id: 'actions',
                    items: [
                      {
                        id: 'delete-filters',
                        label: 'Borrar filtros',
                        isActive: false,
                        action: handleOnReset
                      },
                      {
                        id: 'toggle-map',
                        label: showMap ? 'Esconder mapa' : 'Mostrar mapa',
                        isActive: false,
                        action: toggleMap
                      }
                    ]
                  }
                ]}
              />
            </HStack>
          </ScrollDragContainer>
        </Flex>
      </Container>

      <HStack h="full" w="full" align="flex-start" spacing={0}>
        <Box
          flex={showMap || !isMobile ? 1 : '0'}
          h={CONTENT_HEIGHT}
          overflow={showMap ? 'initial' : 'hidden'}
          visibility={showMap ? 'visible' : 'hidden'}
          w="full"
          display={showMap ? 'block' : 'none'} // If not displayed, hide this block
          position="relative">
          <MapWithMarkers
            initialCoordinates={initialFilters?.coordinates as MapLocation}
            initialRadius={initialFilters?.maxDistance}
            zoom={zoom}
            onZoomUpdate={handleOnZoomUpdate}
            isMapInteractive={!isLoading}
            items={markersData || []}
            center={mapInfo}
            onDragEnd={handleOnMapDrag}
            onUpdate={({ center, radius }) => {
              if (center && radius) {
                const finalLat = center.lat()
                const finalLng = center.lng()

                if (
                  mapInfo.lat === finalLat &&
                  finalLng === mapInfo.lng &&
                  radius === mapInfo.radius
                ) {
                  return
                }

                debouncedDispatchMapInfo({
                  type: ReducerActionType.Set,
                  payload: {
                    lat: finalLat,
                    lng: finalLng,
                    radius
                  }
                })
              }
            }}
          />
        </Box>
        <Box
          style={{ WebkitOverflowScrolling: 'touch' }}
          bg="background.surface"
          h={CONTENT_HEIGHT}
          overflowY={isMobile && showMap ? 'hidden' : 'auto'}
          flex={isMobile ? (showMap ? '0' : '1') : showMap ? 0.6 : 1}>
          <Suspense fallback={<Text>Lista de propiedades</Text>}>
            <RealEstateList
              hasReachedBottom={(reached) => setListHasReachedBottom(!reached)}
              isMobile={isMobile}
              showMap={showMap}
              onPageSize={(v) => {
                if (v) {
                  setSize(parseInt(PageSizeOptions[v]))
                }
              }}
              onSort={(v) => {
                const dict = {
                  [RealEstateSortOptions.Closest]: { distance: 1 },
                  [RealEstateSortOptions.SaleHighToLow]: { 'price.sale.price': -1 },
                  [RealEstateSortOptions.SaleLowToHigh]: { 'price.sale.price': 1 },
                  [RealEstateSortOptions.Latest]: { id: -1 }
                }
                if (v) {
                  setSort(dict[RealEstateSortOptions[v]])
                }
              }}
              onReset={handleOnReset}
              items={paginatedData}
              totalItems={pagination.totalDocs}
              page={currentPage}
              totalPages={pagination.totalPages}
              setPage={setCurrentPage}
              isLoading={isLoading}
            />
          </Suspense>
        </Box>
      </HStack>
    </VStack>
  )
}

export default HomepageSearch
