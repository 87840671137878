import React, { FC } from 'react'
import { Container, HStack, StackProps } from '@chakra-ui/react'
import { ImageBox } from '@/app/components/common/ImageBox'
import { useRouter } from 'next/navigation'

interface NavBarProps extends StackProps {}

const NavBar: FC<NavBarProps> = ({ ...props }) => {
  const router = useRouter()
  return (
    <HStack
      as="nav"
      bg="background.default"
      justify="space-between"
      position="fixed"
      spacing={0}
      transition="all 0.75s cubic-bezier(.33,1,.68,1) .3s"
      w="full"
      zIndex="modal"
      align="center"
      {...props}>
      <Container as={HStack} justify="space-between" h="full" px={4} py={2} maxW="container.xl">
        <ImageBox
          cursor="pointer"
          onClick={() => router.push('/')}
          h="full"
          imgProps={{
            title: 'CBRE',
            alt: 'cbre-logo',
            src: '/logo.png',
            fill: true,
            style: {
              objectFit: 'contain'
            },
            quality: 100,
            priority: true,
            sizes: '10em'
          }}
          w={40}
        />

        <ImageBox
          h="full"
          flex={0}
          minW={'50px'}
          imgProps={{
            title: 'MEXICO',
            alt: 'fl-mx',
            src: '/mexico.png',
            fill: true,
            style: {
              objectFit: 'contain'
            },
            quality: 100,
            priority: true,
            sizes: '10em'
          }}
          w={40}
        />
      </Container>
    </HStack>
  )
}

export default NavBar
