import * as React from 'react'
import { Action, ReducerActionType } from '@/hooks/useSetReset'
import PricePicker from './PricePicker'
import { AllowedPrices } from '@/core/real-estate/typings'

interface PriceFilterWrapperProps {
  priceFilter: { min: AllowedPrices; max: AllowedPrices }
  dispatch: React.Dispatch<Action<{ min: AllowedPrices; max: AllowedPrices }>>
  onUpdate?: (values: (keyof AllowedPrices)[]) => void
  disableAll?: boolean
}

const getEnumKeyByValue = (value: AllowedPrices): keyof typeof AllowedPrices => {
  const entry = Object.entries(AllowedPrices).find(([, v]) => v === value)
  if (!entry) throw new Error(`Value ${value} not found in AllowedPrices`)
  return entry[0] as keyof typeof AllowedPrices
}

export const PriceFilterWrapper = ({
  priceFilter,
  dispatch,
  disableAll
}: PriceFilterWrapperProps) => {
  const getEnumValue = (key: keyof typeof AllowedPrices): AllowedPrices => {
    return AllowedPrices[key] as AllowedPrices
  }

  const setMinPrice = (key: keyof typeof AllowedPrices) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...priceFilter, min: value }
    })
  }

  const setMaxPrice = (key: keyof typeof AllowedPrices) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...priceFilter, max: value }
    })
  }

  return (
    <PricePicker
      w="full"
      disableAll={disableAll}
      minValue={getEnumKeyByValue(priceFilter.min)}
      maxValue={getEnumKeyByValue(priceFilter.max)}
      onMinChange={setMinPrice}
      onMaxChange={setMaxPrice}
    />
  )
}
