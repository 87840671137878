import React, { useCallback, useRef } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useGoogleMap,
  useLoadScript
} from '@react-google-maps/api'
import { MapCenter } from '@/core/typings'
import { Divider, Flex, Text, VStack } from '@chakra-ui/react'
import { MapLocation, RealEstateLightDTO } from '@/core/real-estate/typings'
import { mapStyle } from '@/app/components/common/CustomMap/map-style'
import ImageCarousel from '@/app/components/common/ImageCarousel'
import { API_KEY, DEFAULT_ZOOM, SEARCH_ITEM_SIZE } from '@/constants'
import { mainMediasFactory } from '@/core/media/utils/main-medias-factory'
import { calculatePercentage } from '@/utils/calculate-percentage'
import './map.css'
import RealEstateSize from '@/app/(public)/components/RealEstateSize'
import RealEstatePrice from '@/app/(public)/components/RealEstatePrice'
import { useRouter, useSearchParams } from 'next/navigation'

const mapContainerStyle = {
  height: '100%',
  width: '100%'
}

type MapWithMarkersProps = {
  items: RealEstateLightDTO[]
  center: MapCenter
  onUpdate: (data: MapData) => void
  zoom?: number
  onZoomUpdate?: (zoom: number) => void
  onDragEnd?: () => void
  isMapInteractive?: boolean
  initialRadius?: number
  initialCoordinates?: MapLocation
}

type MapData = {
  center?: google.maps.LatLng | null
  radius?: number | null
}

export const MapWithMarkers: React.FC<MapWithMarkersProps> = ({
  isMapInteractive,
  onUpdate,
  items,
  center,
  zoom,
  onZoomUpdate,
  initialRadius,
  initialCoordinates,
  onDragEnd
}) => {
  const [selected, setSelected] = React.useState<RealEstateLightDTO | null>(null)
  const prevState = React.useRef<{ center: google.maps.LatLng | null; radius: number | null }>({
    center: null,
    radius: null
  })

  const options = {
    styles: mapStyle,
    streetViewControl: isMapInteractive,
    scaleControl: isMapInteractive,
    mapTypeControl: isMapInteractive,
    panControl: isMapInteractive,
    zoomControl: isMapInteractive,
    rotateControl: isMapInteractive,
    fullscreenControl: isMapInteractive,
    controlSize: 25,
    gestureHandling: isMapInteractive ? 'auto' : 'none'
  }

  const cardWidth = calculatePercentage(SEARCH_ITEM_SIZE, 75) || SEARCH_ITEM_SIZE

  const mapRef = useRef<google.maps.Map | null>(null)

  const setInitialZoom = useCallback((map: google.maps.Map, initialDistance?: number) => {
    const bounds = new google.maps.LatLngBounds()
    const center = map.getCenter()

    if (!center) {
      return DEFAULT_ZOOM
    }

    if (!initialDistance) {
      return DEFAULT_ZOOM
    }

    const northEast = google.maps.geometry.spherical.computeOffset(center, initialDistance, 45)
    const southWest = google.maps.geometry.spherical.computeOffset(center, initialDistance, 225)
    bounds.extend(northEast)
    bounds.extend(southWest)
    map.fitBounds(bounds)

    return map.getZoom() || DEFAULT_ZOOM
  }, [])

  function handleZoomChanged(this: google.maps.Map) {
    const z = this.getZoom()
    if (!z) return
    onZoomUpdate?.(z)
  }

  const ChildComponent = () => {
    const mapInstance = useGoogleMap()

    const updateState = useCallback(() => {
      if (mapInstance) {
        if (prevState.current.center === null && initialCoordinates) {
          mapInstance.setCenter({ lng: initialCoordinates[0], lat: initialCoordinates[1] })
        }

        if (prevState.current.radius === null && initialRadius) {
          setInitialZoom(mapInstance, initialRadius)
        }

        const newCenter = mapInstance.getCenter() || null
        const newRadius = getRadius(mapInstance) || null

        if (
          !prevState.current.center ||
          prevState.current.center.lat() !== newCenter?.lat() ||
          prevState.current.center.lng() !== newCenter?.lng() ||
          prevState.current.radius !== newRadius
        ) {
          prevState.current = { center: newCenter, radius: newRadius }

          onUpdate(prevState.current)
        }
      }
    }, [mapInstance])

    React.useEffect(() => {
      if (mapInstance) {
        const listener = google.maps.event.addListener(mapInstance, 'idle', updateState)
        mapRef.current = mapInstance

        // Remove the listener when the component is unmounted
        return () => google.maps.event.removeListener(listener)
      }
    }, [mapInstance, updateState])

    return null
  }

  const searchParams = useSearchParams()
  const router = useRouter()

  const handleItemClick = useCallback(() => {
    if (!selected) return
    const searchQueries = searchParams.toString()
    router.push(`/propiedades/${selected.slug}${searchQueries ? '?' + searchQueries : ''}`)
  }, [router, searchParams, selected])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY!,
    libraries: ['geometry', 'places']
  })

  if (!isLoaded) {
    return null
  }

  if (loadError) {
    return (
      <Flex alignItems="center" justifyContent="center" w="100vw" h="100vh">
        <Text color="red.400">Error al cargar el mapa: {loadError.message}</Text>
      </Flex>
    )
  }

  return (
    <GoogleMap
      id="search-map"
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      onZoomChanged={handleZoomChanged}
      center={center}
      onDragStart={() => setSelected(null)}
      onDragEnd={() => onDragEnd?.()}
      options={options}>
      <ChildComponent />
      <MarkerClusterer
        options={{
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        }}>
        {(clusterer) => (
          <>
            {items.map((item) => (
              <Marker
                key={item.id}
                position={{ lat: item.location.coordinates[1], lng: item.location.coordinates[0] }}
                clusterer={clusterer}
                onClick={() => {
                  if (isMapInteractive) {
                    setSelected(item)
                  }
                }}
                onMouseOver={() => {
                  if (isMapInteractive) {
                    setSelected(item)
                  }
                }}
              />
            ))}
          </>
        )}
      </MarkerClusterer>

      {selected ? (
        <InfoWindow
          position={{
            lat: selected.location.coordinates[1],
            lng: selected.location.coordinates[0]
          }}
          onCloseClick={() => {
            setSelected(null)
          }}>
          <VStack cursor="pointer" onClick={handleItemClick} maxW={cardWidth}>
            <ImageCarousel
              w={cardWidth}
              h={cardWidth}
              imgSrcs={mainMediasFactory(selected.medias).map((m) => m.thumb)}
            />
            <VStack p={3} w="full" align="flex-start">
              <Text w="full" color="gray.500" fontWeight="medium">
                {selected.variant} a la {selected.contractType?.toLowerCase()}
              </Text>
              <Text fontSize="md" fontWeight="bold" color="primary">
                {selected.name}
              </Text>
              <Divider />
              <RealEstateSize data={selected.size} />
              <RealEstatePrice data={selected.price} />
            </VStack>
          </VStack>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  )
}

const getRadius = (map: google.maps.Map) => {
  const bounds = map.getBounds()

  if (!bounds) {
    return
  }

  // Calculate the distance between the center of the map and the north(center to north) and east(center to east) directions.
  const center = bounds.getCenter()
  const north = new google.maps.LatLng(bounds.getNorthEast().lat(), center.lng())
  const east = new google.maps.LatLng(center.lat(), bounds.getNorthEast().lng())

  // Use spherical util functions to compute the distances
  const distanceToNorth = google.maps.geometry.spherical.computeDistanceBetween(center, north)
  const distanceToEast = google.maps.geometry.spherical.computeDistanceBetween(center, east)

  // Radius: smaller one between center to north and center to east distance.
  return Math.min(distanceToNorth, distanceToEast)
}
