import { ContractType, Latitude, Longitude, RealEstateGroup } from '@/core/real-estate/typings'

export type SearchResultsMetadata = {
  event: 'search_results_metadata'
  total_elements: number
  total_pages: number
  search_correlation_id: string
}

export type SearchResultsPagination = {
  event: 'search_results_pagination'
  action: 'prev' | 'next' | 'click_page'
}

export type SearchResultsClick = {
  event: 'search_results_click'
  position: number
  realestate_id: string
}

export type MapInteraction = {
  event: 'map_interaction'
  type: 'drag' | 'zoom'
  zoom_level?: number
}

export type UserSearch = {
  event: 'user_search'
  max_distance: number
  lat: Latitude
  lng: Longitude
  contract_type?: ContractType
  group?: RealEstateGroup[]
  address_input?: string
  sort: string
  size_max?: number
  size_min?: number
  price_max?: number
  price_min?: number
  page: number
  limit: number
  search_correlation_id: string
}

export type UserSearchResetClick = {
  event: 'user_search_reset_click'
}

export type ContactBrokerOpen = {
  event: 'contact_broker_open'
  broker_id: string
}

export type ContactBrokerClose = {
  event: 'contact_broker_close'
  broker_id: string
  duration_seconds: number
}

export type RealestateDetailSendBrokerForm = {
  event: 'realestate_detail_send_broker_form'
  broker_id: string
  message_length: string
}

export type RealestateDetailFileClick = {
  event: 'realestate_detail_file_click'
}

export type RealestateDetailFileDownload = {
  event: 'realestate_details_file_download'
  file_id: string
}

export type RealestateDetailVideoRepositoryClick = {
  event: 'realestate_detail_video_repository_click'
  url: string
}

export type RealestateDetailGalleryOpen = {
  event: 'realestate_detail_gallery_open'
}

export type RealestateDetailGalleryClose = {
  event: 'realestate_detail_gallery_close'
  duration_seconds: number
}

export type Share = {
  event: 'share'
  share_type: 'link' | 'social'
}

export type RealestateDetailMapClick = {
  event: 'realestate_detail_map_click'
}

export type RealestateDetailBrokerTelephoneClick = {
  event: 'realestate_detail_broker_telephone_click'
  broker_id: string
}

export type RealestateDetailBrokerMailClick = {
  event: 'realestate_detail_broker_mail_click'
  broker_id: string
}

export type RealestateDetailOtherRealestateClick = {
  event: 'realestate_detail_other_realestate_click'
  realestate_id: string
}

export type DataLayerEvent =
  | SearchResultsMetadata
  | SearchResultsPagination
  | SearchResultsClick
  | Share
  | MapInteraction
  | UserSearch
  | UserSearchResetClick
  | ContactBrokerOpen
  | RealestateDetailSendBrokerForm
  | RealestateDetailFileClick
  | RealestateDetailFileDownload
  | RealestateDetailGalleryOpen
  | RealestateDetailMapClick
  | RealestateDetailBrokerTelephoneClick
  | RealestateDetailBrokerMailClick
  | RealestateDetailOtherRealestateClick
  | RealestateDetailVideoRepositoryClick
  | RealestateDetailGalleryClose
  | ContactBrokerClose

export const gtmEvent = <T extends DataLayerEvent>(name: T['event'], params: Omit<T, 'event'>) => {
  if (typeof window === 'undefined') return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: name,
    client_timestamp: new Date().toISOString(),
    ...params
  })
}
