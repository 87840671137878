import React, { FC } from 'react'
import { Avatar, Button, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react'
import ContactBrokerModal from '@/app/components/modals/ContactBrokerModal'
import { BrokerDTO } from '@/core/broker/typings'
import { RealEstateDTO, RealEstateLightDTO } from '@/core/real-estate/typings'
import { variantUrls } from '@/core/media/utils/variant-urls'

interface BrokersButtonsProps {
  brokers: BrokerDTO[]
  realEstate: RealEstateLightDTO | RealEstateDTO
}

const BrokersButtons: FC<BrokersButtonsProps> = ({ brokers, realEstate }) => {
  return (
    <Wrap w="full">
      {brokers.length
        ? brokers.map((b) => {
            const broker = b
            return (
              <WrapItem
                transitionDuration="0.25s"
                _hover={{ transform: 'translateY(-1.5px)', shadow: 'md' }}
                as={Button}
                size="md"
                key={broker.id}
                rounded="full"
                py={1}
                px={1}
                bg="background.surface">
                <ContactBrokerModal
                  trigger={
                    <HStack w="full" h="full" spacing={0.5}>
                      <Avatar src={variantUrls(broker.img)['600x600']} h={9} w={9} />
                      <Text px={2} flex={1} fontSize="sm" fontWeight="medium">
                        {broker.name}
                      </Text>
                    </HStack>
                  }
                  realEstate={realEstate}
                  broker={broker}
                />
              </WrapItem>
            )
          })
        : null}
    </Wrap>
  )
}

export default BrokersButtons
