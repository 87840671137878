import { forwardRef, useState } from 'react'
import Image, { ImageProps } from 'next/image'
import { Box, BoxProps } from '@chakra-ui/react'

interface ImageBoxProps extends BoxProps {
  imgProps: ImageProps
}

export const ImageBox = forwardRef<HTMLImageElement, ImageBoxProps>(
  ({ imgProps, ...rest }, ref) => {
    const [imgError, setImgError] = useState(false)

    const handleError = () => {
      setImgError(true)
    }

    return (
      <Box position="relative" {...rest}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image
          ref={ref}
          {...imgProps}
          src={imgError ? '/default-cbre.jpg' : imgProps.src}
          onError={handleError}
        />
      </Box>
    )
  }
)

ImageBox.displayName = 'ImageBox'
