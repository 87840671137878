import * as React from 'react'
import { Select, SelectProps } from '@chakra-ui/react'

interface DropdownProps<T> extends SelectProps {
  enum: { [key in keyof T]: string | number }
  onOptionSelection: (value: keyof T | null) => void
  formatOption?: (key: keyof T, value: string) => string
  onToggle?: (isOpen: boolean) => void
}

export const Dropdown = <T,>({
  enum: enumObj,
  onOptionSelection,
  formatOption,
  value = '',
  onToggle,
  ...props
}: DropdownProps<T>) => {
  const handleOptionSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value !== '' ? (event.target.value as keyof T) : null
    onOptionSelection(selectedValue)
  }

  const format = (key: keyof T, value: string) => {
    if (formatOption) return formatOption(key, value)
    return value
  }

  const handleFocus = () => {
    if (onToggle) {
      onToggle(true)
    }
  }

  const handleBlur = () => {
    if (onToggle) {
      onToggle(false)
    }
  }

  return (
    <Select
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleOptionSelection}
      value={value}
      {...props}>
      {Object.entries(enumObj).map(([key, enumValue]) => (
        <option key={key} value={key}>
          {format(key as keyof T, String(enumValue))}
        </option>
      ))}
    </Select>
  )
}
