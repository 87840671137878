import { useCallback, useEffect, useReducer, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import { AggregatePaginateResult } from 'mongoose'
import { ErrorResponse, SortObject } from '@/core/typings'
import useOnScreen from '@/hooks/useOnScreen'
import { gtmEvent, SearchResultsPagination } from '@/utils/gtm'

enum PaginationReducerActionType {
  Set = 'set',
  Reset = 'reset'
}

interface PaginationReducerAction<T> {
  type: PaginationReducerActionType
  payload?: AggregatePaginateResult<T>
}

const sortReducer = (state: SortObject, action: SortObject) => {
  return { ...state, ...action }
}

const DEFAULT_SIZE = 10

export const usePaginate = <
  T extends {
    id: Record<string, string> | string
  }
>({ idKey = 'id', infiniteScroll = false } = {}) => {
  const initialPaginateState: AggregatePaginateResult<T> = {
    docs: [],
    totalDocs: 0,
    limit: 0,
    hasPrevPage: false,
    hasNextPage: false,
    totalPages: 0,
    offset: 0,
    pagingCounter: 0
  }

  const paginateReducer = (
    state: AggregatePaginateResult<T>,
    { type, payload }: PaginationReducerAction<T>
  ) => {
    switch (type) {
      case PaginationReducerActionType.Set: {
        if (payload) {
          return { ...state, ...payload }
        }

        return state
      }
      case PaginationReducerActionType.Reset:
        return initialPaginateState
      default:
        return state
    }
  }

  const [error, setError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentSize, setCurrentSize] = useState(DEFAULT_SIZE)
  const [currentSort, dispatchSort] = useReducer(sortReducer, { distance: 1 })
  const [pagination, dispatchPagination] = useReducer(paginateReducer, initialPaginateState)
  const [data, setData] = useState<T[] | null>(null)

  const fetch = useCallback(
    async (fetcher: () => Promise<AggregatePaginateResult<T> | ErrorResponse>) => {
      setIsLoading(true)
      const result = await fetcher()

      if ('errors' in result) {
        const e = result.errors?.toString()
        setError(e)
        return
      }

      const content = result.docs.map((o) => {
        if (typeof o.id !== 'string') {
          return { ...o, id: JSON.stringify(o.id) }
        }

        return o
      })

      dispatchPagination({ type: PaginationReducerActionType.Set, payload: result })
      setData((prev) => {
        if (prev && infiniteScroll) {
          return uniqBy([...prev, ...content], idKey)
        }

        return content
      })
      setIsLoading(false)
    },
    [idKey, infiniteScroll]
  )

  const reset = useCallback(() => {
    setError(undefined)
    setCurrentPage(1)
    setCurrentSize(DEFAULT_SIZE)
    dispatchSort({ distance: 1 })
    dispatchPagination({ type: PaginationReducerActionType.Reset })
    setData(null)
  }, [])

  const handleSetCurrentPage = useCallback((page: number) => {
    gtmEvent<SearchResultsPagination>('search_results_pagination', { action: 'click_page' })
    setCurrentPage(page)
  }, [])

  const next = useCallback(() => {
    if (!pagination.nextPage) {
      return
    }
    gtmEvent<SearchResultsPagination>('search_results_pagination', { action: 'next' })
    setCurrentPage(pagination.nextPage)
    return pagination.nextPage
  }, [pagination.nextPage])

  const prev = useCallback(() => {
    if (!pagination.prevPage) {
      return
    }
    gtmEvent<SearchResultsPagination>('search_results_pagination', { action: 'prev' })
    setCurrentPage(pagination.prevPage)
    return pagination.prevPage
  }, [pagination.prevPage])

  const setSize = useCallback((size: number) => {
    setCurrentSize(size)
    setCurrentPage(1)
  }, [])
  const setSort = useCallback((sort: SortObject) => dispatchSort(sort), [])

  const { ref, isIntersecting, observer } = useOnScreen()

  useEffect(() => {
    if (isIntersecting && pagination.hasNextPage) {
      observer?.disconnect()

      if (infiniteScroll) {
        next()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We don't want to add additional dependencies because it will cause infinite re renders since a new observer is created when new items are fetched
  }, [isIntersecting, pagination.hasNextPage])

  return {
    fetch,
    data,
    reset,
    next,
    prev,
    setSize,
    setSort,
    pagination,
    error,
    isLoading,
    currentSize,
    currentSort,
    currentPage,
    loadMoreRef: ref,
    setCurrentPage: handleSetCurrentPage
  }
}
