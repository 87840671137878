import { ImageVariants } from '@/core/media/typings'

export const variantUrls = (originalUrl: string) => {
  const variantUrls: Record<ImageVariants, string> = {} as Record<ImageVariants, string>

  Object.keys(ImageVariants).forEach((variant) => {
    variantUrls[variant as ImageVariants] = originalUrl.replace('image', `image-${variant}`)
  })

  return variantUrls
}
