import * as React from 'react'
import { type ChangeEvent, useEffect, useRef } from 'react'
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import CheckboxOptions, { CheckboxOption } from '@/app/components/common/CheckboxOptions'
import Popover from '@/app/components/common/Popover'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

interface MultiSelectProps<T> extends InputProps {
  options: CheckboxOption<T>[]
  onUpdate?: (values: (keyof T)[]) => void
  selected: (keyof T)[]
  handleStringCheckBoxes: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder: string
}

export const MultiSelectDropdown = <T,>({
  options,
  onUpdate,
  selected,
  handleStringCheckBoxes,
  flex,
  placeholder,
  ...props
}: MultiSelectProps<T>) => {
  useEffect(() => {
    onUpdate?.(selected)
  }, [selected, onUpdate, options])

  const { isOpen, onToggle, onClose } = useDisclosure()

  const ref = useRef<HTMLDivElement | null>(null)

  useOutsideClick({
    ref: ref,
    handler: () => onClose()
  })

  return (
    <Box flex={flex}>
      <Popover
        contentProps={{
          maxWidth: 'calc(100vw - var(--chakra-space-12))',
          width: 'auto',
          minW: 'var(--chakra-sizes-xs)',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        placement="bottom-start"
        {...{ isOpen }}
        target={
          <InputGroup>
            <Input
              ref={ref}
              cursor="default"
              onClick={onToggle}
              readOnly
              value={
                selected.length
                  ? selected
                      .map((id) => options.find((option) => option.value === id)?.label)
                      .join(', ')
                  : placeholder
              }
              {...props}
            />
            <InputRightElement cursor="pointer" onClick={onToggle}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </InputRightElement>
          </InputGroup>
        }
        title="">
        <Box w="sm">
          <CheckboxOptions<T>
            onSelect={handleStringCheckBoxes}
            options={options}
            selected={selected as string[]}
          />
        </Box>
      </Popover>
    </Box>
  )
}
