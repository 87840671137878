'use client'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { FaCopy } from 'react-icons/fa6'
import { CgShare } from 'react-icons/cg'
import CustomModal from '@/app/components/common/CustomModal'
import SocialShareButtons from '@/app/components/common/SocialShareButtons'
import { gtmEvent, Share } from '@/utils/gtm'
import { createTinyUrl } from '@/app/api/tiny-url/handlers'

interface ShareLinkModalProps extends Partial<IconButtonProps> {}

const ShareLinkModal: FC<ShareLinkModalProps> = ({ ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const toast = useToast()

  const handleClick = useCallback(async () => {
    const text = inputRef.current?.value || ''
    try {
      gtmEvent<Share>('share', { share_type: 'link' })

      await navigator.clipboard.writeText(text)
      toast({
        title: 'URL copiada.',
        status: 'success',
        duration: 1000,
        isClosable: true
      })
    } catch (err) {
      console.error(err)
      toast({
        title: err instanceof Error ? err.message : 'Algo salió mal.',
        status: 'error',
        duration: 1000,
        isClosable: true
      })
    }
  }, [toast])

  const [url, setUrl] = useState('')
  const [shortUrl, setShortUrl] = useState('')

  const fetchTinyUrl = useCallback(async () => {
    const short = await createTinyUrl(url)
    if (!short) return
    setShortUrl(short)
  }, [url])

  useEffect(() => {
    ;(async () => {
      await fetchTinyUrl()
    })()
  }, [fetchTinyUrl])

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOnSocialClick = () => {
    gtmEvent<Share>('share', { share_type: 'social' })
  }

  return (
    <CustomModal
      {...{ isOpen, onOpen, onClose }}
      size={{ base: 'xs', sm: 'sm', md: 'lg' }}
      trigger={
        <IconButton
          aria-label="share"
          borderColor="primary"
          borderWidth={1}
          minW={8}
          boxSize={8}
          icon={<Icon as={CgShare} boxSize={4} />}
          {...props}
        />
      }>
      <VStack spacing={10} py={{ base: 4, md: 6 }} px={{ base: 1, md: 4 }} w={'full'}>
        <Heading as="h2" fontSize="xl">
          Comparte con tus amigos
        </Heading>
        <HStack spacing={0} align="flex-end" w="full">
          <FormControl id="link">
            <FormLabel fontSize="sm">Compartir url</FormLabel>
            <Input borderRightRadius="none" ref={inputRef} value={shortUrl} readOnly />
          </FormControl>
          <Button
            borderLeftRadius="none"
            borderRightRadius="md"
            onClick={handleClick}
            leftIcon={<Icon as={FaCopy} boxSize={4} />}>
            Copiar
          </Button>
        </HStack>
        <SocialShareButtons onLinkClick={handleOnSocialClick} url={url} title="CBRE Propiedades" />
      </VStack>
    </CustomModal>
  )
}

export default ShareLinkModal
