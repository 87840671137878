export type BaseCoordinates = {
  lat: number
  lng: number
}

export type BaseAddress = {
  streetNumber?: string
  streetName?: string
  city?: string
  region?: string
  postalCode?: string
  country?: string
}

export interface BasePlace extends Partial<BaseCoordinates> {
  id: string
  htmlStr?: string
  formattedAddress?: string
  distance?: string
  address?: BaseAddress
  type?: PlaceType
}

export enum PlaceType {
  Country = 5,
  AdministrativeArea = 7,
  Locality = 12,
  Neighborhood = 14,
  Route = 15,
  Intersection = 17,
  Premise = 18,
  PointOfInterest = 19,
  Unrecognized = 13
}

export function getPlaceTypeRadius(placeType?: PlaceType): number | null {
  const radiusMap: Record<PlaceType, number | null> = {
    [PlaceType.Country]: 50000,
    [PlaceType.AdministrativeArea]: 25000,
    [PlaceType.Locality]: 8000,
    [PlaceType.Neighborhood]: 2000,
    [PlaceType.Route]: 1000,
    [PlaceType.Intersection]: 200,
    [PlaceType.Premise]: 100,
    [PlaceType.PointOfInterest]: 500,
    [PlaceType.Unrecognized]: null
  }

  if (placeType) {
    return radiusMap[placeType]
  } else {
    return null
  }
}
