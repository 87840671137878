import * as React from 'react'
import { Action, ReducerActionType } from '@/hooks/useSetReset'
import SizePicker from './SizePicker'
import { AllowedSizes } from '@/core/real-estate/typings'

interface SizeFilterWrapperProps {
  sizeFilter: { mtsMin: AllowedSizes; mtsMax: AllowedSizes }
  dispatch: React.Dispatch<Action<{ mtsMin: AllowedSizes; mtsMax: AllowedSizes }>>
  onUpdate?: (values: (keyof AllowedSizes)[]) => void
  disableAll?: boolean
}

const getEnumKeyByValue = (value: AllowedSizes): keyof typeof AllowedSizes => {
  const entry = Object.entries(AllowedSizes).find(([, v]) => v === value)
  if (!entry) throw new Error(`Value ${value} not found in AllowedSizes`)
  return entry[0] as keyof typeof AllowedSizes
}

export const SizeFilterWrapper = ({ sizeFilter, dispatch, disableAll }: SizeFilterWrapperProps) => {
  const getEnumValue = (key: keyof typeof AllowedSizes): AllowedSizes => {
    return AllowedSizes[key] as AllowedSizes
  }

  const setMtsMin = (key: keyof typeof AllowedSizes) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMin: value }
    })
  }

  const setMtsMax = (key: keyof typeof AllowedSizes) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMax: value }
    })
  }

  return (
    <SizePicker
      w="full"
      disableAll={disableAll}
      minValue={getEnumKeyByValue(sizeFilter.mtsMin)}
      maxValue={getEnumKeyByValue(sizeFilter.mtsMax)}
      onMinChange={setMtsMin}
      onMaxChange={setMtsMax}
    />
  )
}
