import { FC } from 'react'
import { Box, StackProps, Text, VStack } from '@chakra-ui/react'
import { Size } from '@/core/real-estate/typings'
import { formatNumber } from '@/utils/format-number'
import _ from 'lodash'

interface RealEstateSizeProps extends StackProps {
  data: Size
}

const RealEstateSize: FC<RealEstateSizeProps> = ({ data, ...props }) => {
  const sizes = [
    { value: data.rentableM2, label: 'rentable' },
    { value: data.officeM2, label: 'oficina' },
    { value: data.plotM2, label: 'terreno' },
    { value: data.warehouseM2, label: 'nave' },
    { value: data.totalConstructionM2, label: 'construcción' }
  ]

  const filteredSizes = sizes.filter((size) => size.value)

  if (
    !data.rentableM2 &&
    !data.officeM2 &&
    !data.plotM2 &&
    !data.totalConstructionM2 &&
    !data.warehouseM2
  ) {
    return null
  }

  return (
    <VStack w="full" h="full" gap={2} {...props}>
      {filteredSizes.map((item, index) => (
        <Box w="full" key={index}>
          {item.value ? (
            <Text fontSize="sm" color="gray.700">
              <i>{_.capitalize(item.label)}:</i>{' '}
              <Text as="span" color="initial">
                {formatNumber(item.value)} m2
              </Text>
            </Text>
          ) : null}
        </Box>
      ))}
    </VStack>
  )
}

export default RealEstateSize
