import { FC, ReactNode } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import CustomDrawer from '@/app/components/common/CustomDrawer'
import { IoFilter } from 'react-icons/io5'

export interface FilterItem {
  title: string
  content: ReactNode
}

interface SearchFiltersDrawerProps extends ButtonProps {
  onReset: () => void
  filters: FilterItem[]
  totalItems: number
}

const SearchFiltersDrawer: FC<SearchFiltersDrawerProps> = ({
  filters,
  onReset,
  totalItems,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'filters-menu'
  })

  return (
    <CustomDrawer
      size="full"
      trigger={
        <Button rightIcon={<Icon boxSize={4} as={IoFilter} />} {...props}>
          Filtros
        </Button>
      }
      {...{ isOpen, onOpen, onClose }}>
      <VStack position="relative" justify="flex-start" spacing={6} py={6} w="full" h="full">
        <Heading w="full" textAlign="left">
          Filtros
        </Heading>
        <Divider />

        <Accordion
          sx={{
            '& > div:first-of-type': {
              borderTop: 'none'
            },
            '& > div:last-of-type': {
              borderBottom: 'none'
            }
          }}
          allowToggle
          w="full"
          pb="10vh">
          {filters.map((item, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton py={6}>
                    <Heading w="full" flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                      {item.title}
                    </Heading>
                    {isExpanded ? <ChevronUpIcon boxSize={5} /> : <ChevronDownIcon boxSize={5} />}
                  </AccordionButton>
                  <AccordionPanel>{item.content}</AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>

        <Flex
          px={4}
          align="center"
          bg="background.default"
          borderTopWidth={1}
          borderColor="gray.200"
          h="10vh"
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            {totalItems} propiedades
          </Text>
          <Button size="lg" variant="ghost" onClick={onReset}>
            Borrar filtros
          </Button>
        </Flex>
      </VStack>
    </CustomDrawer>
  )
}

export default SearchFiltersDrawer
