import { useCallback, useEffect, useRef } from 'react'

const useTimer = (start: boolean): [() => number, () => void] => {
  const timerStartRef = useRef<number | null>(null)
  const elapsedTimeRef = useRef<number>(0)

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null

    if (start) {
      timerStartRef.current = timerStartRef.current || Date.now()

      interval = setInterval(() => {
        const currentTime = Date.now()

        const timerStart = timerStartRef.current
        if (timerStart !== null) {
          elapsedTimeRef.current += currentTime - timerStart
          timerStartRef.current = currentTime
        }
      }, 100)
    } else {
      timerStartRef.current = null
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [start])

  const getElapsedTime = useCallback(() => elapsedTimeRef.current, [])

  const resetTimer = useCallback(() => {
    elapsedTimeRef.current = 0
    timerStartRef.current = null
  }, [])

  return [getElapsedTime, resetTimer]
}

export default useTimer
