import * as React from 'react'
import { useRef } from 'react'
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Action, ReducerActionType } from '@/hooks/useSetReset'
import Popover from '@/app/components/common/Popover'
import PricePicker from './PricePicker' // Import reusable PricePicker
import { formatNumber } from '@/utils/format-number'
import { AllowedPrices } from '@/core/real-estate/typings'

interface PricePopoverFilterWrapperProps extends InputProps {
  priceFilter: { min: AllowedPrices; max: AllowedPrices }
  dispatch: React.Dispatch<Action<{ min: AllowedPrices; max: AllowedPrices }>>
  onUpdate?: (values: (keyof AllowedPrices)[]) => void
}

const getEnumKeyByValue = (value: AllowedPrices): keyof typeof AllowedPrices => {
  const entry = Object.entries(AllowedPrices).find(([, v]) => v === value)
  if (!entry) throw new Error(`Value ${value} not found in AllowedPrices`)
  return entry[0] as keyof typeof AllowedPrices
}

export const PricePopoverFilterWrapper = ({
  priceFilter,
  dispatch,
  flex,
  ...props
}: PricePopoverFilterWrapperProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const ref = useRef<HTMLDivElement | null>(null)

  const setMinPrice = (key: keyof typeof AllowedPrices) => {
    const value = AllowedPrices[key]
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...priceFilter, min: value }
    })
  }

  const setMaxPrice = (key: keyof typeof AllowedPrices) => {
    const value = AllowedPrices[key]
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...priceFilter, max: value }
    })
  }

  useOutsideClick({
    ref: ref,
    handler: () => {
      onClose()
    }
  })

  return (
    <Box flex={flex}>
      <Popover
        contentProps={{
          maxWidth: 'calc(100vw - var(--chakra-space-12))',
          width: 'auto',
          minW: 'var(--chakra-sizes-xs)',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        placement="bottom-start"
        {...{ isOpen }}
        target={
          <InputGroup>
            <Input
              ref={ref}
              cursor="default"
              onClick={onToggle}
              readOnly
              value={
                priceFilter.min !== '0' || priceFilter.max !== '0'
                  ? `${
                      priceFilter.min && formatNumber(parseInt(priceFilter.min, 10)) !== '0'
                        ? `$ ${formatNumber(parseInt(priceFilter.min, 10))}`
                        : 's/min'
                    } - ${
                      priceFilter.max && formatNumber(parseInt(priceFilter.max, 10)) !== '0'
                        ? `$ ${formatNumber(parseInt(priceFilter.max, 10))}`
                        : 's/max'
                    }`
                  : 'Precio'
              }
              {...props}
            />
            <InputRightElement cursor="pointer" onClick={onToggle}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </InputRightElement>
          </InputGroup>
        }
        title="">
        <PricePicker
          py={2}
          px={4}
          title="Precio"
          minValue={getEnumKeyByValue(priceFilter.min)}
          maxValue={getEnumKeyByValue(priceFilter.max)}
          onMinChange={setMinPrice}
          onMaxChange={setMaxPrice}
        />
      </Popover>
    </Box>
  )
}
