import * as React from 'react'
import { useRef } from 'react'
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Action, ReducerActionType } from '@/hooks/useSetReset'
import Popover from '@/app/components/common/Popover'
import { formatNumber } from '@/utils/format-number'
import SizePicker from './SizePicker'
import { AllowedSizes } from '@/core/real-estate/typings'

interface SizePopoverFilterWrapperProps extends InputProps {
  sizeFilter: { mtsMin: AllowedSizes; mtsMax: AllowedSizes }
  dispatch: React.Dispatch<Action<{ mtsMin: AllowedSizes; mtsMax: AllowedSizes }>>
  onUpdate?: (values: (keyof AllowedSizes)[]) => void
}

const getEnumKeyByValue = (value: AllowedSizes): keyof typeof AllowedSizes => {
  const entry = Object.entries(AllowedSizes).find(([, v]) => v === value)
  if (!entry) throw new Error(`Value ${value} not found in AllowedSizes`)
  return entry[0] as keyof typeof AllowedSizes
}

const getEnumValue = (key: keyof typeof AllowedSizes): AllowedSizes => {
  return AllowedSizes[key] as AllowedSizes
}

export const SizePopoverFilterWrapper = ({
  sizeFilter,
  dispatch,
  flex,
  ...props
}: SizePopoverFilterWrapperProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const ref = useRef<HTMLDivElement | null>(null)

  const setMtsMin = (key: keyof typeof AllowedSizes) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMin: value }
    })
  }

  const setMtsMax = (key: keyof typeof AllowedSizes) => {
    const value = getEnumValue(key)
    dispatch({
      type: ReducerActionType.Set,
      payload: { ...sizeFilter, mtsMax: value }
    })
  }

  useOutsideClick({
    ref: ref,
    handler: () => {
      onClose()
    }
  })

  return (
    <Box flex={flex}>
      <Popover
        contentProps={{
          maxWidth: 'calc(100vw - var(--chakra-space-12))',
          width: 'auto',
          minW: 'var(--chakra-sizes-xs)',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        placement="bottom-start"
        {...{ isOpen }}
        target={
          <InputGroup>
            <Input
              ref={ref}
              cursor="default"
              onClick={onToggle}
              readOnly
              value={
                sizeFilter.mtsMin !== '0' || sizeFilter.mtsMax !== '0'
                  ? `${
                      sizeFilter.mtsMin && formatNumber(parseInt(sizeFilter.mtsMin)) !== '0'
                        ? `${formatNumber(parseInt(sizeFilter.mtsMin))} m2`
                        : 's/min'
                    } - ${
                      sizeFilter.mtsMax && formatNumber(parseInt(sizeFilter.mtsMax)) !== '0'
                        ? `${formatNumber(parseInt(sizeFilter.mtsMax))} m2`
                        : 's/max'
                    }`
                  : 'Tamaño'
              }
              {...props}
            />
            <InputRightElement cursor="pointer" onClick={onToggle}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </InputRightElement>
          </InputGroup>
        }
        title="">
        <SizePicker
          py={2}
          px={4}
          title="Tamaño"
          minValue={getEnumKeyByValue(sizeFilter.mtsMin)}
          maxValue={getEnumKeyByValue(sizeFilter.mtsMax)}
          onMinChange={setMtsMin}
          onMaxChange={setMtsMax}
        />
      </Popover>
    </Box>
  )
}
