import React, { FC } from 'react'
import {
  Box,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  StackDivider,
  StackProps,
  Text,
  VStack
} from '@chakra-ui/react'
import ImageCarousel from '@/app/components/common/ImageCarousel'
import { mainMediasFactory } from '@/core/media/utils/main-medias-factory'
import RealEstateListItem from '@/app/(public)/components/RealEstateListItem'
import { FaSquare } from 'react-icons/fa'
import { RealEstateLightDTO } from '@/core/real-estate/typings'

interface RealEstateItemVerticalProps extends StackProps {
  item: RealEstateLightDTO
  onItemClick: (item: RealEstateLightDTO) => void
  noBrokers?: boolean
  noHighlights?: boolean
}

const RealEstateItemVertical: FC<RealEstateItemVerticalProps> = ({
  item,
  onItemClick,
  noBrokers,
  noHighlights,
  ...props
}) => {
  return (
    <VStack
      borderColor="border"
      borderWidth={1}
      h="full"
      bg="background.default"
      rounded="md"
      overflow="hidden"
      spacing={0}
      {...props}>
      <VStack spacing={0} w="full" divider={<StackDivider />}>
        <Box
          w="full"
          overflow="hidden"
          sx={{
            aspectRatio: '16 / 9'
          }}>
          <ImageCarousel
            w="full"
            h="full"
            imgSrcs={mainMediasFactory(item.medias).map((m) => m.thumb)}
          />
        </Box>
        <RealEstateListItem
          w="full"
          onClick={() => onItemClick(item)}
          flex={1}
          p={2}
          {...{ item, noBrokers }}
        />
      </VStack>
      {!noHighlights && item.highlights.length ? (
        <SimpleGrid
          bg="background.default"
          columns={{ base: 3, md: 2 }}
          w="full"
          gap={{ base: 1, md: 2 }}
          p={{ base: 2, md: 4 }}>
          {item.highlights.map((highlight, index) => (
            <GridItem key={index} flex={1}>
              <HStack spacing={1} w="full">
                <Icon as={FaSquare} color="primary" boxSize={1} />
                <Text fontSize="sm">{highlight}</Text>
              </HStack>
            </GridItem>
          ))}
        </SimpleGrid>
      ) : null}
    </VStack>
  )
}

export default RealEstateItemVertical
