import * as React from 'react'
import { Heading, HStack, StackProps, VStack } from '@chakra-ui/react'
import { Dropdown } from '@/app/components/common/Dropdown'
import { AllowedPrices } from '@/core/real-estate/typings'
import { formatNumber } from '@/utils/format-number'

interface PricePickerProps extends StackProps {
  minValue: keyof typeof AllowedPrices
  maxValue: keyof typeof AllowedPrices
  onMinChange: (value: keyof typeof AllowedPrices) => void
  onMaxChange: (value: keyof typeof AllowedPrices) => void
  disableAll?: boolean
  title?: string
}

const PricePicker: React.FC<PricePickerProps> = ({
  title,
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  disableAll,
  ...props
}) => {
  return (
    <VStack w="full" {...props}>
      {title ? (
        <Heading w="full" as="h6" fontSize="lg">
          {title}
        </Heading>
      ) : null}
      <HStack w="full" spacing="3">
        <Dropdown
          isDisabled={disableAll}
          value={minValue}
          formatOption={(_key, value) => {
            const formattedNumber = formatNumber(parseInt(value, 10))
            if (formattedNumber !== '0') {
              return `$ ${formattedNumber}`
            } else {
              return 's/min'
            }
          }}
          flex={0.5}
          placeholder="min"
          enum={AllowedPrices}
          onOptionSelection={(e) => {
            if (e) {
              onMinChange(e)
            }
          }}
        />

        <Dropdown
          isDisabled={disableAll}
          value={maxValue}
          formatOption={(_key, value) => {
            const formattedNumber = formatNumber(parseInt(value, 10))
            if (formattedNumber !== '0') {
              return `$ ${formattedNumber}`
            } else {
              return 's/max'
            }
          }}
          flex={0.5}
          placeholder="max"
          enum={AllowedPrices}
          onOptionSelection={(e) => {
            if (e) {
              onMaxChange(e)
            }
          }}
        />
      </HStack>
    </VStack>
  )
}

export default PricePicker
