export interface MediaDTO {
  id: string
  url: string
  type: MediaType
  isMain: boolean
  name: string
}

export enum MediaType {
  Image = 'image',
  PDF = 'pdf',
  Video = 'video'
}

export interface MediaIdentifier {
  mediaId: string
}

export enum ImageVariants {
  'thumb' = 'thumb',
  '1920x1080' = '1920x1080',
  '600x600' = '600x600'
}
